@import '../../../variables';

.header {
    cursor: pointer;
    transition: $transition-base;

    &:hover {
        color: $primary;
    }
}
