@import '../../variables';

.dialCode {
    width: min-content !important;
}

.preview {
    overflow-x: scroll;
}

.code {
    height: 195px;
}
