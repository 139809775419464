@import '../../variables';

.rc-color-picker-panel-inner {
    position: relative;
    border-radius: 0.25rem;
    border: 1px solid $card-border-color;
    padding-bottom: 0.5rem;
}

.rc-color-picker-panel-wrap {
    margin: 5px 0 0;
    height: 30px;
    width: 100%;
    position: relative;
}

.rc-color-picker-panel-wrap-preview {
    position: absolute;
    right: 8px;
}

.rc-color-picker-panel-wrap-ribbon {
    position: absolute;
    left: 8px;
    top: 0;
    right: 43px;
    height: 30px;
}

.rc-color-picker-panel {
    width: 218px;
    background-color: $white;
    box-sizing: border-box;
    outline: none;
    z-index: 9;
    border-radius: 0.25rem;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.rc-color-picker-panel * {
    box-sizing: border-box;
}

.rc-color-picker-panel-open {
    display: block;
}

.rc-color-picker-panel-close {
    display: none;
}

.rc-color-picker-panel-preview {
    height: 30px;
    width: 30px;
    overflow: hidden;
    border-radius: 0.25rem;
    background-image: url('data:image/png;base64,R0lGODdhCgAKAPAAAOXl5f///ywAAAAACgAKAEACEIQdqXt9GxyETrI279OIgwIAOw==');
}

.rc-color-picker-panel-preview span,
.rc-color-picker-panel-preview input[type=color] {
    position: absolute;
    display: block;
    height: 100%;
    width: 30px;
    border-radius: 0.25rem;
}

.rc-color-picker-panel-preview input[type=color] {
    opacity: 0;
}

.rc-color-picker-panel-board {
    position: relative;
    font-size: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin: 8px 8px 0px;
}

.rc-color-picker-panel-board span {
    position: absolute;
    border-radius: 10px;
    border: 1px solid $white;
    width: 9px;
    height: 9px;
    margin: -4px 0 0 -4px;
    left: -999px;
    top: -999px;
    z-index: 2;
}

.rc-color-picker-panel-board-hsv {
    width: 200px;
    height: 150px;
    position: relative;
    z-index: 1;
    border-radius: 0.25rem;
}

.rc-color-picker-panel-board-value {
    border-radius: 0.25rem;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 2;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB2aWV3Qm94PSIwIDAgMSAxIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIj48bGluZWFyR3JhZGllbnQgaWQ9Imxlc3NoYXQtZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPjxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9InJnYigwLDAsMCkiIHN0b3Atb3BhY2l0eT0iMCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIxIi8+PC9saW5lYXJHcmFkaWVudD48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2xlc3NoYXQtZ2VuZXJhdGVkKSIgLz48L3N2Zz4=);
    background-image: linear-gradient(to bottom, transparent 0%, $black 100%);
}

.rc-color-picker-panel-board-saturation {
    border-radius: 0.25rem;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB2aWV3Qm94PSIwIDAgMSAxIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIj48bGluZWFyR3JhZGllbnQgaWQ9Imxlc3NoYXQtZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPjxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiNmZmZmZmYiIHN0b3Atb3BhY2l0eT0iMSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0icmdiKDAsMCwwKSIgc3RvcC1vcGFjaXR5PSIwIi8+PC9saW5lYXJHcmFkaWVudD48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2xlc3NoYXQtZ2VuZXJhdGVkKSIgLz48L3N2Zz4=);
    background-image: linear-gradient(to right, $white 0%, transparent 100%);
}

.rc-color-picker-panel-board-handler {
    border-radius: 0.25rem;
    cursor: crosshair;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
}

.rc-color-picker-panel-ribbon {
    position: relative;
    height: 100%;
    border-radius: 0.25rem;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB2aWV3Qm94PSIwIDAgMSAxIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIj48bGluZWFyR3JhZGllbnQgaWQ9Imxlc3NoYXQtZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPjxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiNmZjAwMDAiIHN0b3Atb3BhY2l0eT0iMSIvPjxzdG9wIG9mZnNldD0iMTAlIiBzdG9wLWNvbG9yPSIjZmY5OTAwIiBzdG9wLW9wYWNpdHk9IjEiLz48c3RvcCBvZmZzZXQ9IjIwJSIgc3RvcC1jb2xvcj0iI2NkZmYwMCIgc3RvcC1vcGFjaXR5PSIxIi8+PHN0b3Agb2Zmc2V0PSIzMCUiIHN0b3AtY29sb3I9IiMzNWZmMDAiIHN0b3Atb3BhY2l0eT0iMSIvPjxzdG9wIG9mZnNldD0iNDAlIiBzdG9wLWNvbG9yPSIjMDBmZjY2IiBzdG9wLW9wYWNpdHk9IjEiLz48c3RvcCBvZmZzZXQ9IjUwJSIgc3RvcC1jb2xvcj0iIzAwZmZmZCIgc3RvcC1vcGFjaXR5PSIxIi8+PHN0b3Agb2Zmc2V0PSI2MCUiIHN0b3AtY29sb3I9IiMwMDY2ZmYiIHN0b3Atb3BhY2l0eT0iMSIvPjxzdG9wIG9mZnNldD0iNzAlIiBzdG9wLWNvbG9yPSIjMzIwMGZmIiBzdG9wLW9wYWNpdHk9IjEiLz48c3RvcCBvZmZzZXQ9IjgwJSIgc3RvcC1jb2xvcj0iI2NkMDBmZiIgc3RvcC1vcGFjaXR5PSIxIi8+PHN0b3Agb2Zmc2V0PSI5MCUiIHN0b3AtY29sb3I9IiNmZjAwOTkiIHN0b3Atb3BhY2l0eT0iMSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2ZmMDAwMCIgc3RvcC1vcGFjaXR5PSIxIi8+PC9saW5lYXJHcmFkaWVudD48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2xlc3NoYXQtZ2VuZXJhdGVkKSIgLz48L3N2Zz4=);
    background-image: linear-gradient(to right, #f00 0%, #f90 10%, #cdff00 20%, #35ff00 30%, #00ff66 40%, #00fffd 50%, #06f 60%, #3200ff 70%, #cd00ff 80%, #f09 90%, #f00 100%);
}

.rc-color-picker-panel-ribbon span {
    position: absolute;
    top: 0;
    height: 100%;
    width: 4px;
    border: 1px solid $black;
    padding: 1px 0;
    margin-left: -2px;
    background-color: $white;
    border-radius: 3px;
}

.rc-color-picker-panel-ribbon-handler {
    position: absolute;
    width: 104%;
    height: 100%;
    left: -2%;
    cursor: pointer;
}

.rc-color-picker-panel-params {
    font-size: 12px;
}

.rc-color-picker-panel-params-input {
    overflow: hidden;
    padding: 2px 8px;
}

.rc-color-picker-panel-params input {
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
    text-align: center;
    padding: 1px;
    margin: 0;
    float: left;
    border-radius: 0.25rem;
    border: 1px solid $input-border-color;
}

.rc-color-picker-panel-params-hex {
    width: 52px;
}

.rc-color-picker-panel-params input[type=number] {
    margin-left: 5px;
    width: 44px;
}

.rc-color-picker-panel-params input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.rc-color-picker-panel-params-lable {
    padding: 2px 8px;
    height: 22px;
    line-height: 18px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.rc-color-picker-panel-params-lable label {
    float: left;
    text-align: center;
}

.rc-color-picker-panel-params-lable-hex {
    width: 52px;
}

.rc-color-picker-panel-params-lable-number {
    margin-left: 5px;
    width: 44px;
    text-transform: uppercase;
}

.rc-color-picker-panel-params-lable-number:hover {
    border-radius: 0.25rem;
    background-color: $secondary;
    cursor: pointer;
}

.rc-color-picker {
    position: absolute;
    left: -9999px;
    top: -9999px;
    z-index: 1000;
}

.rc-color-picker-wrap {
    display: inline-block;
}
