/* eslint-disable css-modules/no-unused-class */

// Import Variables
@import '../../variables';

// Import Bootstrap
@import '~bootstrap/scss/bootstrap';

// Text selection
::-moz-selection {
    background: $primary;
    color: $white;
    text-shadow: none;
}

::selection {
    background: $primary;
    color: $white;
    text-shadow: none;
}

// No-JavaScript notice
noscript {
    display: block;
    margin: 2em auto;
    padding: 1em;
    font: 100 2em sans-serif;
    text-align: center;
}

// Font Awesome button fix
.btn-lg {
    [class*='fa-'] {
        vertical-align: -0.2rem;
    }
}

// Code blocks
pre {
    white-space: pre-wrap;
    word-wrap: break-word;
}
